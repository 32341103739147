<template>
    <div class="start bg-primary text-beige">
        <!-- <div class="start-center"> -->
        <img alt="" src="@/assets/logo_beige.svg" />
        <div class="bird-wrapper">
            <img alt="" class="bird-shadow" src="@/assets/bird_splash_shadow.png" />
            <img alt="" class="bird" src="@/assets/bird_splash.png" />
        </div>
        <router-link to="/login" class="button outline beige shadow">Sign in</router-link>
        <small>If you're a new fan</small>
        <router-link to="/register" class="button shadow">Create account</router-link>
        <!-- </div> -->
        <div class="start-end">
            <small>A stage for every artist - an artist for every wallet</small>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Start'
}
</script>

<style lang="scss" scoped>
.start {
    height: 100vh;
    display: grid;
    place-content: center;
    row-gap: 2rem;
    text-align: center;
    justify-items: center;

    .bird {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;

        &-wrapper {
            display: none;
            position: relative;
            background: linear-gradient(90deg, rgba(244, 242, 253, 0.2), rgba(244, 242, 253, 0.2));
            border-radius: 50%;
            height: 214px;
            width: 214px;
        }

        &-shadow {
            position: absolute;
            bottom: 0;
            right: 10px;
            z-index: 1;
        }
    }
    @media only screen and (min-width: 768px) {
        /* For desktop: */
        .bird-wrapper {
            display: block;
        }
    }

    a {
        width: 250px;
    }
}
</style>
